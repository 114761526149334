import Vue from 'vue'
import VueInternationalization, { LocaleMessages } from 'vue-i18n'

Vue.use(VueInternationalization)

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages: LocaleMessages = {}

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)

        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })

    return messages
}

const options = {
    locale: getLocalLanguage(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-EN',
    messages: loadLocaleMessages(),
}

function getLocalLanguage(): string {
    if (navigator.language.includes('en')) {
        return 'en-EN'
    } else if (navigator.language.includes('de')) {
        return 'de-DE'
    } else if (navigator.language.includes('es')) {
        return 'es-ES'
    } else if (navigator.language.includes('it')) {
        return 'it-IT'
    } else if (navigator.language.includes('nl')) {
        return 'nl-NL'
    } else if (navigator.language.includes('pt')) {
        return 'pt-PT'
    } else if (navigator.language.includes('fr')) {
        return 'fr-FR'
    }

    return navigator.language
}

export default new VueInternationalization(options)
