<template>
    <div id="device-referential-kpi-component">
        <v-card class="shadow-md">
            <v-card-title>
                <v-row align="center">
                    <v-col cols="3">
                        <v-img :src="device_referential_kpi.picture" class="ma-auto" contain max-height="50" max-width="50" />
                    </v-col>
                    <v-col cols="9">
                        <h5>{{ device_referential_kpi.name }}</h5>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider />

            <v-card-text class="pt-4 pb-4">
                <v-row class="text-center">
                    <v-col cols="4">
                        V: {{ device_referential_kpi.hard_version }}
                    </v-col>
                    <v-divider vertical />
                    <v-col cols="4">
                        <v-img :src="device_referential_kpi.network_icon" class="align-start" contain max-height="25" />
                    </v-col>
                    <v-divider vertical />
                    <v-col cols="4">
                        {{ $t('manage_stock.in_stock') }}:  {{ device_referential_kpi.total_devices_in_stock }}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { IDeviceReferentialKpi } from '@/models/IDeviceReferentialKpi'

export default {
    name: 'DeviceReferentialKpiComponent',
    props: {
        device_referential_kpi: {
            type: IDeviceReferentialKpi,
            require: true,
        },
    },
}
</script>
