import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"device-referential-kpi-component"}},[_c(VCard,{staticClass:"shadow-md"},[_c(VCardTitle,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VImg,{staticClass:"ma-auto",attrs:{"src":_vm.device_referential_kpi.picture,"contain":"","max-height":"50","max-width":"50"}})],1),_c(VCol,{attrs:{"cols":"9"}},[_c('h5',[_vm._v(_vm._s(_vm.device_referential_kpi.name))])])],1)],1),_c(VDivider),_c(VCardText,{staticClass:"pt-4 pb-4"},[_c(VRow,{staticClass:"text-center"},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v(" V: "+_vm._s(_vm.device_referential_kpi.hard_version)+" ")]),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"4"}},[_c(VImg,{staticClass:"align-start",attrs:{"src":_vm.device_referential_kpi.network_icon,"contain":"","max-height":"25"}})],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t('manage_stock.in_stock'))+": "+_vm._s(_vm.device_referential_kpi.total_devices_in_stock)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }